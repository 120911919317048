<template>
  <div v-if="variant == 'none'" style="border-bottom: 0px solid gray">
    <v-textarea
      :label="placeholder"
      filled
      auto-grow
      :rows="2"
      v-model="content"
      :error-messages="errors"
      :tabindex="tabindex"
      :autofocus="autofocus ? autofocus : false"
      :style="hasStyle ? hasStyle : ''"
    ></v-textarea>
  </div>
  <div v-else style="border-bottom: 2px solid gray" class="my-2">
    <!-- Use the component in the right place of the template -->
    <tiptap-vuetify
      :autofocus="autofocus ? autofocus : false"
      v-model="content"
      :extensions="
        variant && variant != null ? extensions[variant] : extensions['default']
      "
      :placeholder="placeholder"
      :card-props="{ elevation: 2 }"
      :toolbar-attributes="{ color: color ? color : 'muted', dark: dark }"
      :error-messages="errors"
      :tabindex="tabindex"
      :style="hasStyle ? hasStyle : ''"
    />
  </div>
</template>

<script>
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Image,
  Heading,
  Bold,
  Italic,
  Strike,
  TodoList,
  TodoItem,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
// import VueTipFileSelector from "@/components/VueTipFileSelector";
import UploadArea from "@/components/UploadArea";
export default {
  props: [
    "initial",
    "tabindex",
    "variant",
    "errors",
    "placeholder",
    "color",
    "dark",
    "autofocus",
    "hasStyle",
  ],
  name: "vue-tip",
  // specify TiptapVuetify component in "components"
  components: { TiptapVuetify },
  data: () => ({
    content: "",
    // declare extensions you want to use
    extensions: {
      none: [],
      minimal: [
        Bold,
        Italic,
        Underline,
        Link,
        [
          Heading,
          {
            options: {
              levels: [1],
            },
          },
        ],
        HardBreak,
      ],
      minimalWithImage: [
        Bold,
        Italic,
        Link,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        [
          Image,
          {
            options: {
              imageSources: [{ component: UploadArea, name: "Paste Area" }],
            },
          },
        ],
      ],
      extended: [
        Bold,
        Italic,
        Underline,
        [
          Heading,
          {
            options: {
              levels: [2],
            },
          },
        ],
        ListItem,
        BulletList,
        Link,
        [
          Image,
          {
            options: {
              imageSources: [
                // { component: VueTipFileSelector, name: "From File" },
                { component: UploadArea, name: "Paste Area" },
              ],
            },
          },
        ],
        Table,
        TableCell,
        TableHeader,
        TableRow,
        HorizontalRule,
        HardBreak,
      ],
      default: [
        Bold,
        Italic,
        Underline,
        Strike,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Blockquote,
        ListItem,
        BulletList,
        OrderedList,
        Link,
        [
          Image,
          {
            options: {
              imageSources: [
                // { component: VueTipFileSelector, name: "From File" },
                { component: UploadArea, name: "Paste Area" },
              ],
            },
          },
        ],
        Code,
        HorizontalRule,
        Paragraph,
        Table,
        TableCell,
        TableHeader,
        TableRow,
        History,
        HardBreak,
      ],
    },
  }),
  created() {
    this.content = this.initial;
  },
  watch: {
    initial(val) {
      if (val === "<p></p>") val = "";
      this.content = val;
    },
    content(val) {
      if (val === "<p></p>") val = "";
      this.$emit("changed", val);
    },
  },
};
</script>
<style >
img {
  width: 99%;
  margin: 0 auto;
}
</style>